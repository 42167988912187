.refer-container {
  color: #fff;
}
.invite-code {
  display: flex;
  gap: 0.8rem;
}
.invite-code input {
  width: 100%;
}
.invite-code button {
  background-color: var(--s);
  width: 150px;
  padding: 15px;
  border-radius: 10px;
  outline: transparent;
  border: transparent;
  color: #fff;
  font-weight: 500;
}
.invite-code button:hover {
  opacity: 0.8;
}

.notice {
  margin-top: 30px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px;
  border-radius: 10px;
}
