.add-product-container {
  padding: 20px;
  background-color: #fff;
  margin-top: 20px;
  gap: 10px;
}
.add-product-container input {
  border: 1px solid #333;
  width: 20%;
  color: #333;
  outline: transparent;
}
.add-product-container input:nth-child(2),
.add-product-container input:nth-child(3) {
  padding: 2px;
  padding-left: 10px;
}
.add-product-container select {
  color: #333 !important;
  width: 20%;
}
.optionContainer li {
  color: #000 !important;
}
.add-product-container .form-fields button,
.add-btn {
  background-color: #333;
  padding: 5px 20px;
  outline: transparent;
  border: transparent;
}
