.filter-container {
  display: flex;
  padding: 20px;
  gap: 10px;
  padding-left: 0;
}
.head {
  display: flex;
  gap: 2rem;
  align-items: center;
}
.filter-container .fields {
  display: flex;
}
.search-btn {
  padding: 8px 20px;
  background-color: var(--s);
  outline: transparent;
  border: transparent;
  border-radius: 10px;
}
.chart-container {
  display: flex;
  padding: 50px;
  margin-bottom: 20px;
  gap: 50px;
  background-color: #fff;
  color: #333;
}
.chartOne {
  width: 50%;
}
.chartTwo {
  width: 50%;
}
.toggle-icon {
  width: 80px;
  height: 40px;
  border-radius: 100px;
  background-color: var(--p);
  padding: 5px;
  transition: 0.3s all ease;
  cursor: pointer;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.4);
}
.toggle-icon.active {
  justify-content: flex-end;
}
.toggle-icon .circle {
  position: absolute;
  top: 50%;
  left: 5%;
  width: 35px;
  height: 35px;
  transition: 0.3s all ease;
  background-color: red;
  transform: translateY(-50%);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.toggle-icon .circle.active {
  left: 50%;
  background-color: var(--s);
}
.admin-dashboard-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  color: #333;
}
.dash-card {
  width: 23%;
  background-color: var(--s);
  color: #fff;
  height: 120px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  padding: 0 40px;
  margin-bottom: 20px;
  cursor: pointer;
}
.dash-card:hover {
  background-color: var(--s);
  opacity: 0.9;
}
.dash-card .count {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.dash-card .icon {
  font-size: 40px;
  margin-bottom: 25px;
  transition: 0.3s all ease;
}
.dash-card:hover.dash-card .icon {
  font-size: 50px;
}

/* ================ */
.admin-recent-things {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.recent-orders {
  width: 100%;
  background-color: #fff;
  color: #333;
  padding: 20px;
  border-radius: 5px;
}

.top-buyer {
  width: 100%;
  background-color: #fff;
  color: #333;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
}

@media screen and (max-width: 992px) {
  .chart-container {
    display: flex;
    flex-wrap: wrap;
  }
  .chartOne,
  .chartTwo {
    width: 100%;
  }
  .dash-card {
    width: 100%;
  }
  .recent-orders,
  .recent-queries {
    width: 100%;
    overflow-x: scroll;
  }
  .recent-queries {
    margin-top: 20px;
  }
}
