.footer-container {
  padding: 50px 20px;
  overflow: hidden;
  padding: 50px 100px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  background-color: var(--p);
  color: #fff;
  position: relative;
}
.tel-icon-cont {
  position: fixed;
  right: 20px;
  bottom: 40px;
  background-color: #26bd68;
  padding: 8px;
  border-radius: 100px;
  z-index: 9999;
  transition: all ease 0.2s;
}

.tel-icon-cont:hover,
.tel-icon-cont:focus {
  transform: scale(1.2);
}
.wa-icon-cont {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: #26bd68;
  padding: 8px;
  border-radius: 100px;
  z-index: 9999;
}
.wa-icon-cont a,
.tel-icon-cont a {
  color: #fff;
}
.wa-icon-cont .icon,
.tel-icon-cont .icon {
  font-size: 30px;
  margin-right: 5px;
}
.footer-container .footer-logo {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
  span {
    color: var(--s);
  }
}
.footer-container .social-media-links {
  display: flex;
  gap: 8px;
}
.social-media-links a {
  color: #fff;
}
.social-media-links .icon {
  font-size: 30px;
}
.quick-links h6 {
  font-size: 20px;
  font-weight: bold;
}
.footer-container ul {
  padding: 0;
}
.footer-container ul li {
  margin-bottom: 5px;
}
.footer-container ul li a {
  font-size: 16px;
  color: #fff;
  position: relative;
  cursor: pointer;
}
.footer-container ul li a:hover {
  color: var(--s);
}
.mobile-sticky-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  padding: 10px 0;
  z-index: 99;
}
.mobile-sticky-footer ul {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.mobile-sticky-footer ul li {
  text-align: center;
  cursor: pointer;
}
.mobile-sticky-footer ul li a {
  color: #ebef29;
  font-size: 12px;
}
.mobile-sticky-footer li.active,
.mobile-sticky-footer li a.active {
  color: #26bd68;
}

@media screen and (max-width: 992px) {
  .footer-container {
    padding: 50px 20px !important;
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 768px) {
  .footer-container {
    padding: 50px 20px;
  }
  .footer-para {
    width: 100%;
  }
  .wa-icon-cont .icon {
    font-size: 40px;
  }
}
@media screen and (max-width: 640px) {
  .footer-container {
    padding: 50px 20px;
  }
  .copyright {
    font-size: 14px;
  }
}
@media screen and (max-width: 420px) {
  .footer-container {
    padding: 50px 20px;
  }
}
@media screen and (max-width: 375px) {
  .footer-container {
    padding: 50px 20px;
  }
}
