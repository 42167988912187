.success-container,
.failure-container {
  background-color: rgba(255, 166, 0, 0.142);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.success-container h2,
.failure-container h2 {
  font-size: 40px;
  margin-bottom: 5px;
}
.success-container .icon,
.failure-container .icon {
  font-size: 60px;
  margin-bottom: 30px;
  color: var(--s);
}
.failure-container .icon {
  color: red;
}
.success-container button,
.failure-container button {
  padding: 10px 20px;
  outline: transparent;
  border: transparent;
  margin-top: 20px;
  background-color: var(--s);
  border-radius: 15px;
  color: #fff;
}
.failure-container .btns {
  display: flex;
  gap: 10px;
}
.failure-container button {
  background-color: red;
}
.failure-container {
  background-color: rgba(255, 0, 0, 0.142);
}
