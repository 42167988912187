.top-3-users-container .top-3-users-content {
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: center;
}
.top-3-users-container .top-3-users-content .top-users-card {
  background-color: #fff;
  border: 3px solid var(--a);
  border-radius: 18px;
  height: auto;
  min-height: 150px;
  padding: 10px;
  position: relative;
  width: 110px;
}
.top-3-users-container .top-3-users-content .users-profile-box {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 5px;
}
.top-3-users-container .top-3-users-content .users-profile-box img {
  width: 42px;
}

.top-3-users-container .top-3-users-content .top-users-card h4 {
  color: var(--s);
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 5px;
  margin-top: 4px;
}
.top-3-users-container .top-3-users-content .top-users-card h5 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0;
  max-height: 2.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
}

.top-3-users-container .top-3-users-content .top-users-card.middle-card {
  scale: 1.2;
  z-index: 1;
}
.shadow {
  box-shadow: 0 0.5rem 1rem #00000026 !important;
  box-shadow: var(--bs-box-shadow) !important;
}
@media screen and (min-width: 993px) {
  .top-3-users-container {
    padding: 3rem 0rem !important;
  }
  .top-3-users-container .top-3-users-content {
    justify-content: space-evenly;
  }
  .top-3-users-container .top-3-users-content .top-users-card {
    background-color: #fff;
    border: 3px solid var(--a);
    border-radius: 18px;
    height: auto;
    min-height: 250px;
    padding: 10px;
    position: relative;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .top-3-users-container .top-3-users-content .users-profile-box img {
    width: 70px;
  }
  .top-3-users-container .top-3-users-content .top-users-card h4 {
    color: var(--s);
    font-size: 1.5rem;
    font-weight: 1000;
    margin-bottom: 5px;
    margin-top: 4px;
  }
  .top-3-users-container .top-3-users-content .top-users-card h5 {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: 1rem;
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 0;
    max-height: 2.4em;
    overflow: hidden;
    text-overflow: ellipsis;
    color: black;
  }
}
