.admin-queries {
  background-color: #fff;
  padding: 20px 10px;
  color: #333;
  overflow-x: scroll;
}
.admin-query-reply {
  display: flex;
  flex-direction: column;
}
.admin-query-reply .query-msg.user {
  align-self: flex-start;
}
.admin-query-reply .query-msg.admin {
  align-self: flex-end;
  background-color: #ffca00;
}
