.flash-container {
  display: flex;
  width: 100%;
  background-color: #fff;
  padding: 10px;
}
.prodhead {
  margin-top: 20px;
}
.flash-products {
  background-color: #fff;
  padding: 10px;
  display: flex;
}
.productss {
  background-color: #fff;
  color: #000;
  overflow-x: scroll;
}
@media screen and (max-width: 786px) {
  .flash-container {
    flex-direction: column;
  }
  .flash-products {
    flex-direction: column;
  }
}
