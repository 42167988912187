.how-it-works {
  padding: 50px 10px;
  width: 100%;
  background-color: var(--c);
  overflow: hidden;
}
.how-it-works h6 {
  font-weight: 600;
  text-transform: uppercase;
  width: 100%;
}
.before-footer .icon {
  font-size: 50px;
  color: var(--s);
}
.before-footer h6 {
  color: #fff;
}
@media screen and (max-width: 992px) {
  .how-it-works {
    padding: 60px 20px;
    text-align: center;
  }
  .choose-better-container::before {
    /* background-image: url('../../svg/hero-mobile.svg'); */
    bottom: -780px;
  }
  .how-it-works h2 {
    font-size: 30px;
    line-height: 35px;
    text-align: center;
  }
}
