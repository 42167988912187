.bg-col {
  background-color: #e6f4ff;
}
.textCol {
  color: var(--s);
}
.timertext {
  color: black;
  font-size: 0.8rem;
  font-weight: bold;

  margin: 0;
}
.timeinfo {
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
}

@media screen and (min-width: 993px) {
  .timeinfo {
    padding: 0.5rem;
  }
  .timertext {
    color: black;
    font-weight: bold;
    font-size: 1.1rem;
    margin: 0;
  }
}
