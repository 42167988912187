.dashboard-container {
  padding: 30px 50px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: var(--p);
  min-height: 61vh;
}
.dashboard-menu {
  width: 18%;
  padding: 20px;
  background-color: var(--p);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3);
  color: #fff;
}
.dashboard-menu ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.dashboard-menu ul li {
  padding: 7px 5px;
}
.dashboard-menu ul li.active {
  background-color: var(--s);
}
.dashboard-menu ul li a {
  color: #fff;
}

/* CONTENT  */
.dashboard-content {
  width: 82%;
  padding: 0 20px;
}
.user-dashboard {
  display: flex;
  gap: 20px;
}
.redeembtn {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background-color: var(--s);
  width: max-content;
  padding: 10px 20px;
  outline: transparent;
  border: transparent;
  border-radius: 15px;
  transition: all 0.2s ease;
}
.redeembtn:hover {
  opacity: 0.8;
  transform: scale(1.05);
}
.redeembtn:disabled {
  opacity: 0.4;
  transform: scale(1);
  cursor: pointer;
}
/* =============== MEDIA QUERY ===============  */
@media screen and (max-width: 992px) {
  .dashboard-container {
    padding: 0;
  }
  .dashboard-menu {
    width: 100%;
  }
  .dashboard-content {
    width: 100%;
    margin-top: 30px;
    overflow-x: scroll;
    padding-bottom: 50px;
  }
  .user-dashboard {
    flex-direction: column;
  }
}
