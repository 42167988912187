.terms-container {
  padding: 50px 200px;
}
.bg-heading {
  padding: 40px 50px;
  background-color: #666;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg-heading h4 {
  font-size: 30px;
  text-align: center;
}

.terms-container h5 {
  color: #000;
  font-weight: 600;
  font-size: 16px;
  position: relative;
  text-transform: uppercase;
}

@media screen and (max-width: 786px) {
  .terms-container {
    padding: 50px 20px;
  }
}
