.dgf-container {
  padding: 25px 100px;
  background-color: var(--p);
  color: #fff;
  text-align: center;
}
.dgf-container h5 {
  color: var(--s);
}
.dgf-c {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.dgf {
  width: 23%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--s);
  padding: 15px 20px;
  border-radius: 10px;
  margin-top: 20px;
}
.dgf .icon {
  color: var(--s);
  font-size: 70px;
  margin-bottom: 10px;
}
.dgf h4 {
  font-weight: 500;
  margin: 0;
}
.dgf p {
  color: #eee;
}

@media screen and (max-width: 786px) {
  .dgf-container {
    padding: 50px 20px;
    background-color: var(--p);
    color: #fff;
    text-align: center;
  }
  .dgf {
    width: 100%;
  }
}
