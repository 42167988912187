.user-accout-details label {
  color: #fff;
}
.user-accout-details h6 {
  color: #fff;
}
.user-accout-details .form-fields {
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 20px;
  border-radius: 15px;
}
.user-accout-details .updatebtn {
  background-color: var(--s);
  width: 100%;
  padding: 10px 20px;
  outline: transparent;
  border: transparent;
  border-radius: 15px;
}

input[type="number"]::-webkit-inner-spin-button {
  display: none;
}

/* Fallback for other browsers */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}
