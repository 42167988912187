@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700;800&display=swap");

:root {
  --p: #000;
  --s: #ff4900;
  --t: #000;
  --c: #333a47;
  --a: #ff8632;
  --b: #ffd2a5;
  --first: #1d39c4;
  --second: #531dab;
  --third: #389e0d;
  --rest: #d4380d;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Jost", sans-serif;
  list-style: none !important;
  text-decoration: none !important;
}
.sc {
  color: #ff4900 !important;
}
ul {
  margin: 0 !important;
  padding: 0 !important;
}
.popp {
  font-family: "Poppins", sans-serif !important;
}
.caveat {
  font-family: "Caveat", cursive;
  font-weight: 500;
}

@media screen and (min-width: 993px) {
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: linear-gradient(#333, #000);
  }
}
