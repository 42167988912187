.title {
  color: #fff;
}
.query-reply-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.back-btnn {
  cursor: pointer;
}
.query-msg {
  background-color: #eee;
  color: #000;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  align-self: flex-start;
  width: 60%;
}
.query-msg.active {
  align-self: flex-end;
  background-color: var(--s);
  color: #fff;
}
