.leaderboard {
  background-color: var(--p);
}
.list {
  display: flex;
  gap: 5rem;
  padding: 0.5rem 0;
  margin: 0;
}
.winner {
  color: var(--first);
  font-weight: bolder;
  background-color: #f0f5ff;
  padding: 0.4rem;
  border-radius: 5px;
}
.second {
  color: var(--second);
  font-weight: bolder;

  background-color: #f9f0ff;
  padding: 0.4rem;
  border-radius: 5px;
}
.third {
  color: var(--third);
  font-weight: bolder;

  background-color: #f6ffed;
  padding: 0.4rem;
  border-radius: 5px;
}
.rest {
  color: var(--rest);
}

.tab {
  /* background-color: gainsboro; */
  border-radius: 5px;
  color: white;
}
