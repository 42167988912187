.no-order-found {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  background-color: #eee;
  padding: 20px;
}

.order-header {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 20px;
}
.setDate {
  display: flex;
  gap: 1rem;
}

.searchField {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  margin-bottom: 0.8rem;
}
.order-search-btn {
  padding: 8px 20px;
  background-color: var(--s);
  color: #fff;
  outline: transparent;
  border: transparent;
  border-radius: 10px;
}
.view-btn {
  padding: 2px 10px;
  outline: transparent;
  border: transparent;
  background-color: var(--s);
  color: #fff;
}
.user-order-container .ant-pagination-item a {
  color: #fff !important;
}
.user-order-container .ant-pagination-item-active a {
  color: var(--s) !important;
}
.user-order-container .tools {
  background-color: var(--t);
  margin-bottom: 20px;
}
.user-order-container .tools input {
  width: 100% !important;
}
.order-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.order-container .order {
  width: 30%;
  background-color: #fff;
  color: #000;
  padding: 10px;
  border-radius: 10px;
}
.order-container .order .order-date {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #333;
  margin-bottom: 10px;
}
.order-container .order .order-img-content {
  display: flex;
  gap: 10px;
}
.orderimg {
  width: 35%;
}
.ordercontent {
  width: 75%;
}
.orderimg img {
  width: 100%;
  border-radius: 10px;
}
.ordercontent p {
  margin: 0;
  font-size: 14px;
}

@media screen and (max-width: 992px) {
  .order-container .order {
    width: 100%;
  }
  .order-header {
    display: block;
    align-items: center;
    gap: 2rem;
    margin-bottom: 0px;
  }
  .setDate div {
    width: 50%;
  }
  .setDate {
    display: flex;
    gap: 1rem;
    width: 95%;
  }

  .searchField {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
  }
  .searchBtn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
  .order-search-btn {
    padding: 4px 10px;
    /* padding: 8px 20px; */
    background-color: var(--s);
    color: #fff;
    outline: transparent;
    border: transparent;
    border-radius: 10px;
  }
}
