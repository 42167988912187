.explore-products-container {
  color: #fff;
  background-color: var(--p);
  padding: 50px 100px;
}
.popular-games {
  margin-bottom: 40px;
}
.popular-games .titlee {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.popular-games .titlee h5 {
  font-size: 35px;
}
.games-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.games-list .game {
  width: 10%;
  border: 1px solid red;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #fff;
  background-color: var(--c);
}
.games-list .game img {
  width: 100%;
  border-radius: 10px;
}
.games-list .game h5 {
  margin: 10px 0;
  font-size: 15px;
  text-align: center;
}

.filter-btns {
  /* padding-bottom: 30px; */
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
  margin-bottom: 15px;
}
.filter-btns button {
  outline: transparent;
  border: transparent;
  margin-right: 5px;
  padding: 6px 25px;
  border-radius: 10px;
  background-color: #ff8632;
  min-width: fit-content;
}
.filter-btns button.active {
  background-color: var(--s);
}

/* flsh sale  */
.flash-sale-container {
  padding: 50px 0;
  padding-top: 0;
}
.flash-sale-container .icon {
  color: var(--s);
  font-size: 35px;
}
.flash-salee {
  padding-right: 20px;
  color: #000;
}
.fsale {
  background-image: linear-gradient(to right, #fa709a 0%, #fee140 100%);
  padding: 15px;
  display: flex;
  gap: 15px;
  border-radius: 10px;
}
.fimg {
  width: 30%;
}
.fimg img {
  width: 100%;
  border: 2px solid #fff;
  border-radius: 10px;
}
.fcontent {
  width: 70%;
}
.fcontent p {
  margin: 0;
  font-size: 15px;
}
.fcontent h5 {
  font-size: 18px;
}
.fcontent .price {
  outline: transparent;
  border: transparent;
  display: block;
  text-align: center;
  border-radius: 10px;
  margin-top: 10px;
  padding: 4px 20px;
  background-color: #000;
  color: #fff;
}
.fcontent .ogPrice {
  text-decoration: line-through !important;
  color: red;
}

@media screen and (max-width: 992px) {
  .explore-products-container {
    padding: 23px 20px;
  }
  .games-list .game {
    width: 28%;
  }
}
@media screen and (max-width: 786px) {
}
@media screen and (max-width: 600px) {
  .games-list .game h5 {
    font-size: 12px;
  }
}
