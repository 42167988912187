.navigation-button {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 25px;
  background-color: var(--p);
}

.navigation-button .navigation-button-div {
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 70px;
  justify-content: center;
  width: 70px;
}

.navigation-button-div .add-icon {
  border: 2px dotted #ff4900;
  border-radius: 20px;
  color: #ff4900;
  font-size: 25px;
  height: 27px;
  width: 27px;
}
.navigation-button .navigation-button-name {
  font-size: 13px;
  font-weight: 600;
  margin-top: 8px;
  color: #fff;
}
.center {
  align-items: center;
  display: flex;
  justify-content: center;
}
