.slick-center {
  opacity: 1 !important;
  position: relative;
  z-index: 99;
  object-fit: cover;
  filter: drop-shadow(0 0 0.75rem #ff4900);
  transform: scale(1.2);
  transition: 0.7s all ease-in-out;
}
.slick-slide img {
  object-fit: cover;
  border-radius: 8px;
}
.slick-slide {
  opacity: 0.8;
}
.hero-container {
  padding: 50px 100px;
  background-color: var(--p);
  position: relative;
}
.hero-container img {
  scale: 1;
  transition: 0.3s all ease-out;
}
.hero-container img:hover {
  scale: 1.02;
}
.image-slider {
  overflow-x: scroll;
  border: 1px solid red;
  padding: 0;
}
.slide {
  padding: 0;
}
.hero-slider-btns .icon {
  font-size: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.5);
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: var(--s);
  }
}
.hero-slider-btns .icon:hover {
  background-color: var(--s);
  color: #000;
}
.slick-arrow {
  display: none !important;
}
.hero-slider-btns .arrow-left {
  left: 3%;
}
.hero-slider-btns .arrow-right {
  right: 3%;
}
@media screen and (max-width: 992px) {
  .hero-container {
    padding: 0;
  }
}
@media screen and (max-width: 786px) {
  .hero-slider-btns .icon {
    font-size: 30px;
  }
}
