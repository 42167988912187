.wallet-dash {
  display: flex;
  padding: 30px 0px;
  padding-top: 0;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
}
.bal {
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  padding: 10px;
}
.wallet-dash-card {
  width: 30%;
  padding: 20px;
  background-color: var(--s);
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: var(--s);
    opacity: 0.9;
  }
}
.wallet-date {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  color: #333;
  margin-bottom: 10px;
}
.wallet-date span {
  position: absolute;
}

.add-money {
  display: flex;
  width: 100%;
  gap: 50px;
}
.bar-img {
  width: 50%;
}
.txn-details {
  width: 50%;
}
.bar-img img {
  width: 100%;
}
.txn-btns button {
  background-color: #eee;
  color: #000;
  padding: 5px 10px;
  margin-right: 5px;
  outline: transparent;
  border: transparent;
}
.txn-btns button.active {
  background-color: var(--s);
  color: #fff;
}

@media screen and (max-width: 992px) {
  .wallet-dash-card {
    width: 100%;
  }
  .add-money {
    padding: 0 10px;
    flex-wrap: wrap;
  }
  .bar-img {
    width: 100%;
  }
  .txn-details {
    width: 100%;
  }
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
