.register-container {
  padding: 50px 20px;
  background-color: var(--p);
  min-height: 100vh;
}
.form-fields input,
.form-fields select,
.form-fields textarea {
  box-shadow: none !important;
  border-radius: 0;
  padding: 15px 10px;
}
.form h6,
.form label {
  color: #000;
}
.register-form {
  padding: 10px;
}
.loginbtns {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.register-form h1 {
  font-weight: 400;
  color: var(--s);
  font-weight: bold;
}
.firstpage p {
  text-align: justify;
  font-size: 14px;
}
.register-btn {
  width: 100%;
  background-color: var(--s);
  padding: 15px;
  color: #fff;
  outline: transparent;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.apply-code-btn {
  padding: 8px 15px;
  width: 180px;
  background-color: #222f3e;
  color: #fff;
  outline: transparent;
  border: transparent;
  &:hover {
    opacity: 0.8;
  }
}
.hr-line {
  position: relative;
  margin-bottom: 20px;
}
.hr-line::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 0 !important;
}

.or-span {
  display: block;
  margin: 10px 0;
  color: #fff;
}
.google-btn-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.google-btn-container button {
  width: 100% !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.forgot-pass {
  color: #fff;
}
.forgot-pass a {
  color: var(--s);
}
.fp-form {
  color: #fff;
}

.login-with-otp-button {
  background-color: transparent;
  border: 1px solid white;
}

@media screen and (max-width: 992px) {
  .forgot-pass h6 {
    font-size: 12px;
  }
}

@media screen and (max-width: 786px) {
  .register-container {
    min-height: 90vh;
  }
  .register-form {
    background-color: #89818a;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    border-radius: 50px 50px 0 0;
    padding-top: 30px;
  }
  .register-form h1 {
    color: #000;
    text-align: start;
    font-size: 25px;
    font-weight: 600;
  }
  .register-form .form-fields input {
    border-radius: 10px;
  }
  .register-btn {
    border-radius: 10px;
  }
  .forgot-pass {
    color: #000;
    text-align: center;
    h6 {
      font-size: 14px;
      display: block;
      margin: 0 auto;
    }
    a {
      color: #000;
      font-weight: 600;
    }
  }
}
