.product-info-container {
  padding: 30px 120px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgb(29, 29, 29) 0%,
    rgb(29, 29, 29) 50%,
    rgb(0, 0, 0) 100%
  );
  color: #fff;
}
.game-name {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px;
  height: auto;
  gap: 20px;
  background-color: #333;
}
.game-name .image {
  /* width: 20%; */
  height: 200px;
}
.game-name .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.game-name .content {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #fff;
}
.game-name .content h4 {
  font-weight: bold;
  margin: 0;
}
.game-name .content p {
  color: #ddd;
  white-space: pre-wrap;
}
.game-name .features {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.game-name .features button {
  outline: transparent;
  border: transparent;
  background-color: var(--s);
  padding: 5px;
  border-radius: 10px;
  margin-right: 5px;
}
/* ============ RIGHT  */
.playername {
  font-size: 14px;
  display: inline;
  width: 100%;
  display: block;
  margin-top: 20px;
  border-radius: 10px;
  text-align: center;
  padding: 10px;
  opacity: 0.7;
}

.yt-container {
  width: 100%;
  background-color: #333;
}
.yt-container h4 {
  font-weight: bold;
  color: #fff;
}
.yt-container p {
  font-size: 14px;
  color: #ddd;
  white-space: pre-wrap;
}
.yt {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.ytbtn {
  padding: 8px 20px;
  outline: transparent;
  border: transparent;
  background-color: var(--s);
  color: #fff;
  border-radius: 10px;
  &:hover {
    opacity: 0.9;
  }
}
.bg-fields {
  background-color: #333;
  padding: 30px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  margin-bottom: 20px;
  color: #000;
}
.bg-fields .player-tag {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3);
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  outline: transparent;
  margin-top: 10px;
}
.bg-fields .player-tag::placeholder {
  color: #ddd;
}
.bg-fields h5 {
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.bg-fields hr {
  color: #fff !important;
}
.bg-fields p {
  white-space: pre-wrap;
}
.bg-fields .icon {
  color: var(--t);
  font-size: 30px;
  margin-right: 5px;
}
.bg-fields .total-value {
  display: flex;
  justify-content: space-between;
  color: #fff;
}
.bg-fields .buy-btn-container {
  margin-top: 20px;
}
.bg-fields .buy-now {
  padding: 12px 20px;
  border-radius: 10px;
  width: 100% !important;
  margin-top: 10px;
  background-color: var(--s);
  color: #fff;
  outline: transparent;
  border: transparent;
}
.coupon-box {
  display: flex;
  gap: 0;
  margin-top: 15px;
}
.coupon-box button {
  padding: 10px;
  margin: 0;
  background-color: var(--t);
  color: #fff;
  outline: transparent;
  border: transparent;
  border-radius: 10px;
}
.coupon-tag {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-top: 15px;
}
.coupon-tag p {
  background-color: greenyellow;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.coupon-tag .icon {
  font-size: 18px;
  color: #000;
}
.remove-coupon {
  background-color: red;
  outline: transparent;
  border: transparent;
  padding: 4px 10px;
  color: #fff;
}
.product-info-content {
  width: 100%;
  display: flex;
  gap: 20px;
}
.product-info-content .left {
  width: 70%;
}
.product-info-content .right {
  width: 40%;
}
.product-info-content select {
  padding: 10px;
  margin: 10px 0;
  margin-bottom: 0;
  width: 100%;
}
.player-tag {
  color: #fff;
}
.product-info-content h3 {
  text-transform: uppercase;
}
.add-to-cart-btn {
  width: 50%;
  background-color: #ffca00;
  padding: 10px;
  outline: transparent;
  border: transparent;
  margin-top: 10px;
}
.safe-checkout-img {
  margin-top: 10px;
}
.product-desc-delivery-details {
  padding: 50px 20px;
}
.tab-btns-desc {
  display: flex;
}
.tab-btns-desc .tab {
  padding: 10px;
  text-align: center;
  width: 50%;
  background-color: #eee;
  cursor: pointer;
}
.tab-btns-desc .tab.active {
  background-color: #ebef29;
}
.product-info-details {
  margin-top: 20px;
  min-height: 250px;
}
.p-amount {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 10px;
}
.amount {
  width: 30%;
  padding: 10px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  background-color: #eee;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  font-size: 12px;
  img {
    width: 40px;
  }
}
.amount.active {
  background-color: var(--s);
  color: #fff;
}

.product-desc {
  padding: 50px 200px;
}
.product-desc p {
  white-space: pre-wrap;
}
.product-desc-delivery-details {
  padding: 50px 20px;
}

/* ====================== PAYMENT  */
.payment-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}
.payment {
  width: 40%;
  padding: 10px 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #fff;
  color: #000;
  cursor: pointer;
}
.payment .icon {
  color: #000;
  margin: 0;
}
.payment img {
  width: 25px;
}
.paytm img {
  width: 30px;
}
.payment.active {
  background-color: var(--s);
  color: #fff;
}
.payment.active .icon {
  color: #fff;
}

/* LOADING  */
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
/* ORDER SUCCESS  */
/* ORDER SUCCESS  */
/* ORDER SUCCESS  */
.order-succcess-container {
  padding: 100px 200px;
  min-height: 100vh;
  color: #fff;
  background: linear-gradient(
    180deg,
    rgba(74, 39, 69, 1) 5%,
    rgba(33, 32, 63, 1) 35%,
    rgba(19, 27, 50, 1) 50%,
    rgba(19, 27, 50, 1) 100%
  );
}
.order-recpt {
  width: 50%;
  background: rgba(255, 255, 255, 0.43);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.order-recpt .order-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.bd {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.buy-popup {
  position: fixed;
  left: 0;
  width: 100%;
  bottom: -100%;
  padding: 20px;
  background-color: #000;
  z-index: 99999;
  border-radius: 50px 50px 0 0;
  border: 2px solid #fff;
  text-align: center;
  transition: 0.5s all ease;
}
.buy-popup.active {
  bottom: 0;
}
.buy-popup .icon {
  font-size: 70px;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
  color: var(--s);
}
.buy-popup h2 {
  font-size: 22px;
}
.buy-popup .orderdetails {
  background-color: #333;
  width: 100%;
  padding: 20px 10px;
  border-radius: 15px;
  .item {
    width: 100%;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    span:nth-child(1) {
      width: 50%;
      text-align: start;
    }
    span:nth-child(2) {
      width: 50%;
      text-align: end;
    }
  }
  .confirmproduct {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 20px;
    .img {
      width: 5%;
      img {
        width: 100%;
        border-radius: 15px;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 70%;
      p {
        margin: 0;
      }
      span,
      .icon {
        color: var(--s);
      }
      .icon {
        display: inline;
        font-size: 20px;
        margin: 0;
        margin-left: 5px;
      }
    }
  }
}
.buy-popup .buybtns {
  width: 100%;
  margin-top: 20px;
  display: flex;
  gap: 10px;
  button {
    width: 50%;
    background-color: var(--s);
    border-radius: 15px;
    padding: 10px 20px;
    border: transparent;
    outline: transparent;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}

@media screen and (max-width: 992px) {
  .product-info-container {
    padding: 50px 20px;
  }
  .game-name .image {
    width: 100%;
    height: auto;
  }
  .game-name .content {
    width: 100%;
  }
  .game-name .features {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .game-name .features button {
    padding: 3px;
    font-size: 10px;
    margin-right: 0;
    border-radius: 5px;
  }
  .yt-container button {
    font-size: 12px;
  }
  .yt-container button .icon {
    font-size: 20px;
  }
  .product-info-content {
    width: 100%;
    padding-left: 0;
  }
  .select-brand select {
    width: 100%;
  }
  .add-to-cart-btn {
    width: 100%;
  }
  .safe-checkout-img {
    width: 100%;
  }
  .product-info-content select,
  .player-tag {
    width: 100%;
  }
  .product-desc {
    padding: 50px 20px;
  }
  .p-amount {
    width: 100%;
  }
  .product-info-container {
    flex-wrap: wrap;
  }
  .product-info-content {
    flex-wrap: wrap;
    gap: 0;
  }
  .product-info-content .left {
    width: 100%;
  }
  .product-info-content .right {
    width: 100%;
  }
}

@media screen and (max-width: 786px) {
  .bg-fields {
    padding: 20px;
  }
  .loading-container {
    min-height: 60vh;
  }
  .order-succcess-container {
    padding: 50px 20px;
    min-height: 60vh;
  }
  .order-recpt {
    width: 100%;
  }
  .amount {
    width: 46%;
    font-size: 10px;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    .pimg {
      width: 100%;
    }
    span {
      font-size: 14px;
    }
  }
  .payment {
    width: 45%;
  }
  .buy-popup .orderdetails {
    .confirmproduct {
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      margin-bottom: 20px;
      .img {
        width: 25%;
        img {
          width: 100%;
          border-radius: 15px;
        }
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .product-info-img .game-name {
    gap: 0px;
    flex-wrap: wrap;
  }
  .product-info-img .game-name img {
    width: 100%;
    height: 200px;
  }
  .payment {
    width: 45%;
    font-size: 12px;
    padding: 10px;
  }
}
@media screen and (max-width: 360px) {
  .payment {
    width: 45%;
    font-size: 10px;
    padding: 10px;
  }
}

/* LOADING WAVE  */
/* LOADING WAVE  */
/* LOADING WAVE  */
/* LOADING WAVE  */
.loading-wave {
  width: 300px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.loading-bar {
  width: 20px;
  height: 10px;
  margin: 0 5px;
  background-color: #3498db;
  border-radius: 5px;
  animation: loading-wave-animation 1s ease-in-out infinite;
}

.loading-bar:nth-child(2) {
  animation-delay: 0.1s;
}

.loading-bar:nth-child(3) {
  animation-delay: 0.2s;
}

.loading-bar:nth-child(4) {
  animation-delay: 0.3s;
}

@keyframes loading-wave-animation {
  0% {
    height: 10px;
  }

  50% {
    height: 50px;
  }

  100% {
    height: 10px;
  }
}
